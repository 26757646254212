import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/LOGO_D.svg'
// import facebook from '../img/social/facebook.svg'
// import instagram from '../img/social/instagram.svg'
// import twitter from '../img/social/twitter.svg'
// import vimeo from '../img/social/vimeo.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-text-white-ter">
          <div className="container">
            <div className="columns">
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    <li>
                      <Link className="navbar-item" to="/">
                        Úvod
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/realizace">
                        Realizace
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/kontakt">
                        Kontakt
                      </Link>
                    </li>
                    <li>
                      <a
                        className="navbar-item"
                        href="/admin/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Admin
                      </a>
                    </li>
                  </ul>

                </section>
              </div>
              <div className="column is-4">
                <section className="has-text-centered">
                  <img
                    src={logo}
                    alt="Jan Všetečka logo"
                    style={{height: '11em'}}
                  />
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="contact-list">
                    <li><strong>Jan Všetečka</strong></li>
                    <li><strong>IČ:</strong> 07912293</li>
                    <li><strong>Telefon:</strong> <a href="tel:+420774747825">+420774747825</a></li>
                    <li><strong>E-mail:</strong> <a href="mailto:jan@zahradyvsetecka.cz">jan@zahradyvsetecka.cz</a></li>
                    <li>
                      <strong>Adresa:</strong> Revoluční 11<br/>
                      Brno-Tuřany 620 00<br/>
                      Brno
                    </li>
                  </ul>
                </section>
              </div>
              {/*<div className="column is-4 social">*/}
              {/*<a title="facebook" target="_blank" href="https://facebook.com">*/}
              {/*  <img*/}
              {/*    src={facebook}*/}
              {/*    alt="Facebook"*/}
              {/*    style={{ width: '1em', height: '1em' }}*/}
              {/*  />*/}
              {/*</a>*/}
              {/*<a title="twitter" href="https://twitter.com">*/}
              {/*  <img*/}
              {/*    className="fas fa-lg"*/}
              {/*    src={twitter}*/}
              {/*    alt="Twitter"*/}
              {/*    style={{ width: '1em', height: '1em' }}*/}
              {/*  />*/}
              {/*</a>*/}
              {/*<a title="instagram" href="https://instagram.com">*/}
              {/*  <img*/}
              {/*    src={instagram}*/}
              {/*    alt="Instagram"*/}
              {/*    style={{ width: '1em', height: '1em' }}*/}
              {/*  />*/}
              {/*</a>*/}
              {/*<a title="vimeo" href="https://vimeo.com">*/}
              {/*  <img*/}
              {/*    src={vimeo}*/}
              {/*    alt="Vimeo"*/}
              {/*    style={{ width: '1em', height: '1em' }}*/}
              {/*  />*/}
              {/*</a>*/}
              {/*</div>*/}
            </div>
          </div>
      </footer>
    )
  }
}

export default Footer
